/* You can add global styles to this file, and also import other style files */
@import 'primeflex/primeflex.css';
@import 'theme/variables';

@font-face {
  font-family: PontanoSans;
  src: url('assets/fonts/PontanoSans-VariableFont_wght.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Signika;
  src: url('assets/fonts/Signika-VariableFont_GRAD,wght.ttf');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  background-color: var(--surface-ground);
  color: var(--text-color);
  padding: 0;
  min-height: 100%;
}

.termsfeed-com---palette-dark .cc-nb-okagree {
  background-color: #f2a521;
}

.termsfeed-com---palette-dark .cc-nb-reject {
  color: #f2a521;
  background-color: unset;
}

.termsfeed-com---palette-dark .cc-nb-changep {
  display: none;
}

.termsfeed-com---palette-dark .cc-cp-foot-save {
  background: #f2a521;
  color: #000;
}

.termsfeed-com---palette-dark .cc-pc-head-lang select {
  color: white;
}

.termsfeed-com---palette-dark .cc-pc-head-lang select:focus {
  box-shadow: 0 0 0 2px #f2a521;
  color: black;
}

.termsfeed-com---pc-dialog
  input[type='checkbox'].cc-custom-checkbox:checked
  + label:before {
  background: #f2a521;
}
