/* Customizations to the designer theme should be defined here */
.p-datepicker {
  .p-datepicker-header {
    .p-datepicker-prev,
    .p-datepicker-next {
      color: $primaryTextColor;

      &:enabled:hover {
        color: $primaryTextColor;
        background-color: rgba(255, 255, 255, 0.2);
      }
    }
  }

  &.p-datepicker-multiple-month {
    .p-datepicker-group {
      border-right: 1px solid rgba(255, 255, 255, 0.2);
    }
  }

  .p-datepicker-buttonbar {
    border-top: 1px solid rgba(255, 255, 255, 0.2);

    .p-button {
      color: $primaryTextColor;

      &:enabled:hover {
        color: $primaryTextColor;
        background-color: rgba(255, 255, 255, 0.2);
      }
    }
  }

  .p-timepicker {
    border-top: 1px solid rgba(255, 255, 255, 0.2);

    .p-link {
      color: $primaryTextColor;

      &:enabled:hover {
        color: $primaryTextColor;
        background-color: rgba(255, 255, 255, 0.2);
      }
    }
  }

  table {
    font-size: $fontSize;
    margin: $calendarTableMargin;

    th {
      color: rgba(255, 255, 255, 0.7);
    }

    td {
      > span {
        &.p-highlight {
          color: $primaryTextColor;
          background: rgba(255, 255, 255, 0.3);
        }
      }

      &.p-datepicker-today {
        > span {
          &.p-highlight {
            color: $primaryTextColor;
            background: rgba(255, 255, 255, 0.3);
          }
        }
      }
    }
  }

  .p-monthpicker {
    .p-monthpicker-month {
      &.p-highlight {
        color: $primaryTextColor;
        background: rgba(255, 255, 255, 0.3);
      }
    }
  }
}

.p-button {
  .p-button-label {
    font-weight: 700;
  }
}

.p-carousel {
  .p-carousel-indicators .p-carousel-indicator.p-highlight button {
    background-color: $primaryColor;
  }
}

.p-galleria {
  .p-galleria-indicators .p-galleria-indicator.p-highlight button {
    background-color: $primaryColor;
  }
}

.p-datatable {
  .p-datatable-tbody {
    > tr {
      &.p-datatable-dragpoint-top > td {
        box-shadow: inset 0 2px 0 0 $primaryColor;
      }

      &.p-datatable-dragpoint-bottom > td {
        box-shadow: inset 0 -2px 0 0 $primaryColor;
      }
    }
  }
}
