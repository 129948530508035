.p-avatar {
  background-color: $avatarBg;
  border-radius: $borderRadius;
  overflow: hidden;

  &.p-avatar-lg {
    width: 3rem;
    height: 3rem;
    font-size: 1.5rem;

    .p-avatar-icon {
      font-size: 1.5rem;
    }
  }

  &.p-avatar-xl {
    width: 4rem;
    height: 4rem;
    font-size: 2rem;

    .p-avatar-icon {
      font-size: 2rem;
    }
  }
}

.p-avatar-group {
  .p-avatar {
    border: 2px solid $panelContentBg;
  }
}
